<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/gift' }"
          >礼物学币模板设置
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="名称：" label-position="left" prop="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            style="width: 300px"
          ></el-input>
        </el-form-item>

        <el-form-item label="内容：" label-position="left" prop="content">
          <el-input
            v-model="form.content"
            maxlength="225"
            style="width: 300px"
            type="textarea"
          ></el-input>
        </el-form-item>

        <el-form-item label="图片：" label-position="left" prop="pic">
          <select-images
            :image="image"
            @onDelete="onDelete"
            @onSelect="onSelect"
          ></select-images>
        </el-form-item>

        <el-form-item label="礼物图标：" label-position="left" prop="pic">
          <select-images
            :image="icon"
            @onDelete="onIconDelete"
            @onSelect="onIconSelect"
          ></select-images>
        </el-form-item>

        <el-form-item label="学币：" label-position="left" prop="credit">
          <el-input-number
            v-model="form.credit"
            :min="0"
            label="学币"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="时长：" label-position="left" prop="duration">
          <el-input-number
            v-model="form.duration"
            :min="0"
            label="学币"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="状态：" label-position="left" prop="status">
          <el-radio v-model="form.status" :label="1" border>启用</el-radio>
          <el-radio v-model="form.status" :label="0" border>禁用</el-radio>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectImages from "../../../components/selectImages/index";

export default {
  name: "add",
  components: { SelectImages },
  data() {
    return {
      loading: false,
      image: "",
      icon: "",
      form: {
        status: 1,
        pic: "",
        credit: 0,
        duration: 5,
        icon: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        pic: [{ required: true, message: "请选择图片", trigger: "change" }],
        icon: [
          { required: true, message: "请选择礼物图表", trigger: "change" },
        ],
        credit: [{ required: true, message: "请输入学币", trigger: "blur" }],
        duration: [{ required: true, message: "请输入时长", trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapActions("gift", ["giftAdd"]),

    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const { res_info } = await this.giftAdd(this.form);
            if (res_info !== "ok") return;
            await this.$router.push("/gift");
            this.$message.success("创建成功");
          } finally {
            this.loading = false;
          }
        }
      });
    },

    onSelect(image) {
      this.image = imageUrl + image.path;
      this.form.pic = image.path;
    },
    onDelete() {
      this.image = "";
      this.form.pic = "";
    },
    onIconSelect(image) {
      this.icon = imageUrl + image.path;
      this.form.icon = image.path;
    },
    onIconDelete() {
      this.icon = "";
      this.form.icon = "";
    },
  },
};
</script>

<style scoped></style>